
// Core components to be included with every mobile app

/* alignment */
import Container from "mobile.vue.components.Container.vue";
import Content from "mobile.vue.components.Content.vue";

/* elements */
import Alert from "mobile.vue.components.Alert.vue";
import Search from "mobile.vue.components.Search.vue";
//import Tabs from "mobile.vue.components.Tabs.vue";
//import Tabs2 from "mobile.vue.components.Tabs2.vue";
//import BottomBar from "mobile.vue.components.BottomBar.vue";
import Drawer from "mobile.vue.components.Drawer.vue";
import FloatingButton from "mobile.vue.components.FloatingButton.vue";
import TitleBar from "mobile.vue.components.TitleBar.vue";
import DatePicker from "mobile.vue.components.DatePicker.vue";

/* tabs */
import Tab from "mobile.vue.components.tabs.Tab.vue";
import Tabs from "mobile.vue.components.tabs.Tabs.vue";
import TabBar from "mobile.vue.components.tabs.TabBar.vue";
import TabBtn from "mobile.vue.components.tabs.TabBtn.vue";

/* sheets */
import BottomSheet from "mobile.vue.components.BottomSheet.vue";
import ActionSheet from "mobile.vue.components.ActionSheet.vue";
import ActionSheetBtn from "mobile.vue.components.ActionSheetBtn.vue";
import MapSheet from "mobile.vue.components.MapSheet.vue";
//import FilterSheet from "mobile.vue.components.FilterSheet.vue";

/* filter */
import FilterPills from "mobile.vue.components.filter.FilterPills.vue";
import FilterSheets from "mobile.vue.components.filter.FilterSheets.vue";
import FilterButtons from 'mobile.vue.components.filter.Buttons.vue';
// import FilterFields from "mobile.vue.components.filter.FilterFields.vue";
// import FilterOrders from "mobile.vue.components.filter.FilterOrders.vue";

/* visual */
import Card from "mobile.vue.components.Card.vue";
import AppCard from "mobile.vue.components.AppCard.vue";
import Pill from "mobile.vue.components.Pill.vue";
import MSection from "mobile.vue.components.Section.vue";
import Accordion from 'mobile.vue.components.Accordion.vue';
import AccordionItem from 'mobile.vue.components.AccordionItem.vue';
import AccordionAddItem from 'mobile.vue.components.AccordionAddItem.vue';

export default {
    install: (app, options) => {
        /* alignment */
        app.component("MContainer", Container);
        app.component("MContent", Content);

        /* elements */
        app.component("MAlert", Alert);
        app.component("MSearch", Search);
        //app.component("MTabs", Tabs);
        //app.component("MTabs2", Tabs2);
        //app.component("MBottomBar", BottomBar);
        app.component("MDrawer", Drawer);
        app.component("MFloatingButton", FloatingButton);
        app.component("MTitleBar", TitleBar);
        app.component("MDatePicker", DatePicker);

        /* tabs */
        app.component("MTab", Tab);
        app.component("MTabs", Tabs);
        app.component("MTabBar", TabBar);
        app.component("MTabBtn", TabBtn);

        /* sheets */
        app.component("MBottomSheet", BottomSheet);
        app.component("MActionSheet", ActionSheet);
        app.component("MActionSheetBtn", ActionSheetBtn);
        app.component("MMapSheet", MapSheet);
        //app.component("MFilterSheet", FilterSheet);

        /* filter */
        app.component("MFilterPills", FilterPills);
        app.component("MFilterSheets", FilterSheets);
        app.component("MFilterButtons", FilterButtons);
        // app.component("MFilterFields", FilterFields);
        // app.component("MFilterOrders", FilterOrders);

        /* visual */
        app.component("MCard", Card);
        app.component("MAppCard", AppCard);
        app.component("MPill", Pill);
        app.component("MSection", MSection);
        app.component("MAccordion", Accordion);
        app.component("MAccordionItem", AccordionItem);
        app.component("MAccordionAddItem", AccordionAddItem);
    },
}
